export const SUPABASE_COOKIE_NAME = 'supabase-token'

export enum DrillType {
  playTheScore = 'PlayTheScore',
  invention = 'Invention',
  playAlong = 'PlayAlong',
}

export enum ExerciseRole {
  objective = 'objective',
  review = 'review',
  warmUp = 'warm-up',
}

export enum ExerciseStatus {
  notStarted = 'notStarted',
  active = 'active',
  resting = 'resting',
  completed = 'completed',
}

export enum Track {
  scales = 'scales',
  chords = 'chords',
  fingers = 'fingers',
  improv = 'improv',
  rhythm = 'rhythm',
  figures = 'figures',
  comping = 'comping',
  earTraining = 'ear-training',
  arranging = 'arranging',
  composing = 'composing',
  playing = 'playing', // What is this?
  sightReading = 'sight-reading',
  general = 'general',
  handIndependence = 'hand-independence',
}

export enum Program {
  classical = 'classical',
  jazz = 'jazz',
  popRock = 'pop-rock',
  latin = 'latin',
  blues = 'blues',
}
